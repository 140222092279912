// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphNew-module__wrapper___pSz_E{height:100%;position:relative}.GraphNew-module__total___eYmBz{top:250px;color:#fff;left:calc(50% - 50px);position:absolute;z-index:11}", "",{"version":3,"sources":["webpack://./src/features/cyberlinks/GraphNew/GraphNew.module.scss"],"names":[],"mappings":"AAIA,kCACE,WAAA,CACA,iBAAA,CAGF,gCACE,SAAA,CACA,UAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".cosmographStyle {\n  //   background-color: red;\n}\n\n.wrapper {\n  height: 100%;\n  position: relative;\n}\n\n.total {\n  top: 250px;\n  color: white;\n  left: calc(50% - 50px);\n  position: absolute;\n  z-index: 11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GraphNew-module__wrapper___pSz_E",
	"total": "GraphNew-module__total___eYmBz"
};
export default ___CSS_LOADER_EXPORT___;
